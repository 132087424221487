import { NgModule } from '@angular/core';
import { ArquiteturaModule } from 'src/app/arquitetura/arquitetura.module';
import { RodadaDeNegociosPage } from './rodada-de-negocios.page';
import { RodadaDeNegociosComponent } from 'src/app/arquitetura/rodada-negocios/rodada-de-negocios/rodada-de-negocios.component';

@NgModule({
  declarations: [
    RodadaDeNegociosPage,
    RodadaDeNegociosComponent
  ],
  imports: [
    ArquiteturaModule
  ]
})
export class RodadaDeNegociosModule { }
