import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { BaseService } from 'src/app/arquitetura/service/base.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import axios from 'axios';

@Injectable()
export class RodadaDeNegociosService extends BaseService {

  constructor(http: HTTP,
    private datePipe: DatePipe) {
    super('', http);
  }

  avaliacao(urlLink: string) {
    return this.http.sendRequest(
      `${environment.api}v2/set-video-pitch/`,
      {
        method: 'post',
        headers: this.configToken(),
        data: {
          videoUrl: urlLink
        },
        serializer: 'json',
    })
      .then(data => JSON.parse(data.data))
  }

  startBusinessRound(businessRoundID: string) {
    return axios.get(
      `${environment.api}v2/business-rounds/${businessRoundID}/start`,
      {
        headers: this.configToken(),
      }
    ).then(response => response.data)
  }

  markAsInterested(businessRoundID: string, companyID: string) {
    return axios.post(
      `${environment.api}v2/business-rounds/${businessRoundID}/customers/${companyID}/interested`,
      null,
      {
        headers: this.configToken(),
      }
    ).then(response => response.data)
  }

  maskAsNotInterested(businessRoundID: string, companyID: string) {
    return axios.post(
      `${environment.api}v2/business-rounds/${businessRoundID}/customers/${companyID}/not-interested`,
      null,
      {
        headers: this.configToken(),
      }
    ).then(response => response.data)
  }
}