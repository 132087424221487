import { AfterViewChecked, Component } from "@angular/core";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { NavigationStart, Router } from "@angular/router";
import { OfertaResumida } from "src/app/shared/model/ofertas/oferta-resumida.model";
import { getAccountId, getToken } from "../../service/base.service";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-painel-ofertas',
  templateUrl: './painel-ofertas.component.html',
  styleUrls: [
    './painel-ofertas.component.scss',
    `../../../../themes/xpory/css/component/minhas-ofertas/painel-ofertas.component.scss`,
    `../../../../themes/xpory-futebol/css/component/minhas-ofertas/painel-ofertas.component.scss`,
    `../../../../themes/fcj-barter/css/component/minhas-ofertas/painel-ofertas.component.scss`,
  ]
})
export class PainelOfertasComponent extends BaseComponent implements AfterViewChecked {
  theme = environment.theme_name;
  ofertas: OfertaResumida[] = [];
  inicio: Date;
  fim: Date;
  dateShowInicio: boolean;
  dateShowFim: boolean;

  count: number = 0;
  offset: number;
  max: number;

  checked: boolean;

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private datePipe: DatePipe,
    private navCtrl: NavController,
    private service: OfertasService) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.carregaSaldo();

    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.checked = false;
        }
      });
  }

  ngAfterViewChecked(): void {
    if (!this.checked) {
      this.carregaSaldo();
      this.checked = true;
    }
  }

  carregaSaldo() {
    this.offset = 0;
    this.max = 12;
    this.ofertas = [];
    var token = getToken();
    var accountId = getAccountId();
    if (this.inicio && this.fim) {
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarMinhasData(token, accountId, inicio, fim, this.offset, this.max).then((resultado) => {
        this.count = resultado.listFullSize;
        this.ofertas = resultado.offers as OfertaResumida[];
      }).catch((ex) => {

      });
    } else {
      this.service.listarMinhas(token, accountId, this.offset, this.max).then((resultado) => {
        this.count = resultado.listFullSize;
        this.ofertas = resultado.offers as OfertaResumida[];
      }).catch((ex) => {

      });
    }
  }

  loadData(event) {
    this.offset += this.max;
    var token = getToken();
    var accountId = getAccountId();

    if (this.inicio && this.fim) {
      var inicio = this.datePipe.transform(this.inicio, 'MM/dd/yyyy')!;
      var fim = this.datePipe.transform(this.fim, 'MM/dd/yyyy')!;

      this.service.listarMinhasData(token, accountId, inicio, fim, this.offset, this.max).then((resultado) => {
        if (event.target) {
          event.target.complete();
        }

        if (resultado) {
          if (!resultado.msg) {
            var ofertas = resultado.offers;
            this.ofertas = this.ofertas.concat(ofertas);
          }
        }
      });
    }
    else {
      this.service.listarMinhas(token, accountId, this.offset, this.max).then((resultado) => {
        if (event.target) {
          event.target.complete();
        }

        if (resultado) {
          if (!resultado.msg) {
            var ofertas = resultado.offers;
            this.ofertas = this.ofertas.concat(ofertas);
          }
        }
      });
    }
  }

  onAdicionar(id: number) {
    this.navCtrl.navigateForward('/core/oferta-detalhe', { queryParams: { id: id } });
  }

  onEditar(id: number) {
    this.navCtrl.navigateForward('/minhas-ofertas/painel-ofertas-cadastro', { queryParams: { id: id } });
  }
}
