import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UsuarioService } from 'src/app/shared/service/perfil/usuario.service';
import { Usuario } from 'src/app/shared/model/perfil/usuario.model';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { clearStorage, getMobile, getUsuarioLogado } from 'src/app/arquitetura/service/base.service';

@Component({
  selector: 'cmp-menu-perfil',
  templateUrl: './menu-perfil.component.html',
  styleUrls: [
    './menu-perfil.component.css',
  ]
})
export class MenuPerfilComponent {

  isLead: boolean;
  usuario: Usuario;
  exibeMenu: boolean;
  @Input() itemExpand!: string;
  @Input() itemSelected!: string;

  showConfirmTelefone: boolean = false;
  codigoConfirmacao: string;

  label = environment.label;
  theme = environment.theme_name;

  logado: boolean;
  financeiro: boolean;
  transferencia: boolean;
  vendas: boolean;
  ofertas: boolean;
  indicacoes: boolean;

  @Input() currentCategory: string | null;

  menuItems = [ 
    {
      category: 'acesso-rapido',
      label: 'ACESSO RÁPIDO',
      items: [
        {
          title: 'Cadastrar Nova Oferta',
          url: '/minhas-ofertas/painel-ofertas-cadastro'
        },
        {
          title: 'Fazer Transferência',
          url: '/transferencias/fazer-transferencia'
        },
        {
          title: 'Validar Voucher',
          url: '/vendas/validar-voucher'
        },
        {
          title: 'Minhas Indicações',
          url: '/indicacoes/painel-minhas-indicacoes'
        },
      ]
    },
    {
      category: 'minha-conta',
      label: 'MINHA CONTA',
      items: [
        {
          title: 'Dashboard',
          url: '/perfil/dashboard'
        },
        {
          title: 'Dados da Conta',
          url: '/perfil/editar-perfil'
        },
        {
          title: 'Endereço',
          url: '/perfil/editar-endereco'
        },
      ]
    },
    {
      category: 'ead',
      label: 'EAD',
      items: [
        {
          title: 'EAD',
          url: '/ead/ead'
        },
      ]
    },
    {
      category: 'rodada-negocios',
      label: 'RODADA DE NEGÓCIOS',
      items: [
        {
          title: 'RODADA DE NEGÓCIOS',
          url: '/rodada-de-negocios/rodada-de-negocios'
        },
      ]
    },
    {
      category: 'transferencias',
      label: 'TRANSFERÊNCIAS',
      items: [
        {
          title: 'Minhas Transferências',
          url: '/transferencias/minhas-transferencias'
        },
        {
          title: 'Fazer transferência de X$',
          url: '/transferencias/fazer-transferencia'
        },
        {
          title: 'Solicitar transferência de X$',
          url: '/transferencias/solicitar-transferencia'
        },
      ]
    },
    {
      category: 'financeiro',
      label: 'FINANCEIRO',
      items: [
        {
          title: 'Saldos e Extratos',
          url: '/financeiro/saldo'
        },
        {
          title: 'Avaliar Estornos',
          url: '/financeiro/estornos'
        },
        {
          title: 'Saldo em X$ a Expirar',
          url: '/financeiro/expirar'
        },
        {
          title: 'Meus Empréstimos',
          url: '/financeiro/meus-emprestimos'
        },
        {
          title: 'Novo Empréstimo',
          url: '/financeiro/solicitar-emprestimo'
        },
        {
          title: 'Adiantamento de Comissão',
          url: '/financeiro/solicitar-antecipacao'
        },
      ]
    },
    {
      category: 'compras',
      label: 'COMPRAS',
      items: [
        {
          title: 'Minhas Compras',
          url: '/compras/painel-compras'
        },
      ]
    },
    {
      category: 'ofertas',
      label: 'OFERTAS',
      items: [
        {
          title: 'Minhas Ofertas',
          url: '/minhas-ofertas/painel-ofertas'
        },
        {
          title: 'Cadastrar Nova Oferta',
          url: '/minhas-ofertas/painel-ofertas-cadastro'
        },
      ]
    }
  ]

  currentRoute: string;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private navCtrl: NavController,
    private servicoUsuario: UsuarioService,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.verificarLogin();

      if (this.currentCategory) {
        this.menuItems = this.menuItems.filter(item => item.category === this.currentCategory);
      }
    });
  }

  carregaUsuario() {
  }

  abrirMenu(url: string){
    this.router.navigate([url]);
  }

  editPerfil(){
    this.router.navigate(['/perfil-editar-perfil']);
  }

  confirmarTelefone(){
    this.showConfirmTelefone = true;
  }

  fecharConfirmacao(){
    this.showConfirmTelefone = false;
  }

  confirmar(){
  }

  reenviarCodigo(){
  }

  isMobile(){
    return getMobile();
  }

  verificarLogin() {
    var usuario = JSON.parse(getUsuarioLogado());
    if (usuario)
      this.logado = true;
  }

  fecharMenu() {
    this.menu.close('menuPrincipal');
  }

  abrirEAD() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/ead/ead']);
  }

  abrirRodadaNegocio() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/rodada-de-negocios/meu-video-pitch']);
  }

  abrirFinanceiro() {
    this.financeiro = !this.financeiro;
    this.transferencia = false;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirSaldoExtrato() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/saldo']);
  }

  abrirEstornos() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/estornos']);
  }

  abrirSaldoExpirar() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/expirar']);
  }

  abrirMeusEmprestimos() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/meus-emprestimos']);
  }

  abrirMeusEmprestimosEmRS() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/meus-emprestimos-em-rs']);
  }

  abrirSolicitarEmprestimo() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/solicitar-emprestimo']);
  }

  abrirSolicitarAntecipacao() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/financeiro/solicitar-antecipacao']);
  }

  abrirTransferencia() {
    this.financeiro = false;
    this.transferencia = !this.transferencia;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirMinhasTransferencias() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/minhas-transferencias']);
  }

  abrirFazerTransferencia() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/fazer-transferencia']);
  }

  abrirSolicitarTransferencia() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/transferencias/solicitar-transferencia']);
  }

  abrirVendas() {
    this.financeiro = false;
    this.transferencia = false;
    this.vendas = !this.vendas;
    this.ofertas = false;
    this.indicacoes = false;
  }

  abrirPainelVendas() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/vendas/painel-vendas']);
  }

  abrirValidarVoucher() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/vendas/validar-voucher']);
  }

  abrirCompras() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/compras/painel-compras']);
  }

  abrirParceiros() {
    if (this.logado) {
      this.financeiro = false;
      this.transferencia = false;
      this.vendas = false;
      this.ofertas = !this.ofertas;
      this.indicacoes = false;
    } else {
      this.menu.close('menuPrincipal');
      this.navCtrl.navigateForward(['/core/parceiros']);
    }
  }

  abrirOfertas() {
    if (this.logado) {
      this.financeiro = false;
      this.transferencia = false;
      this.vendas = false;
      this.ofertas = !this.ofertas;
      this.indicacoes = false;
    } else {
      this.menu.close('menuPrincipal');
      this.navCtrl.navigateForward(['/core/ofertas']);
    }
  }

  abrirMinhasOfertas() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/minhas-ofertas/painel-ofertas']);
  }

  abrirCadastroOferta() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/minhas-ofertas/painel-ofertas-cadastro']);
  }

  abrirIndicacoes() {
    this.financeiro = false;
    this.transferencia = false;
    this.vendas = false;
    this.ofertas = false;
    this.indicacoes = !this.indicacoes;
  }

  abrirMinhasIndicacoes() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/indicacoes/painel-indicacao']);
  }

  abrirNovaIndicacao() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/indicacoes/painel-minhas-indicacoes']);
  }

  onSair() {
    clearStorage();
    this.navCtrl.navigateForward(['/core/home']).then(() => {
      window.location.reload();
    });
  }

  abrirPainelEmpreendedor() {
    this.router.navigate([]).then(result => { window.open(`https://empreendedor.xpory.com/`, '_blank'); });
  }

  onFinalizarConta() {
    this.menu.close('menuPrincipal');
    this.navCtrl.navigateForward(['/excluir-conta']);
  }
}
