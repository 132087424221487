import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { SentryIonicErrorHandler } from './shared/utils/SentryIonicErrorHandler';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { IonContent, IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './page/core/home/home.module';
import { ArquiteturaModule } from './arquitetura/arquitetura.module';
import { LoginModule } from './page/onboarding/login/login.module';
import { LoginService } from './shared/service/login/login.service';
import { CadastrarModule } from './page/onboarding/cadastrar/cadastrar.module';
import { EnderecoService } from './shared/service/endereco/endereco.service';
import { RecuperarModule } from './page/onboarding/recuperar/recuperar.module';
import { UsuarioService } from './shared/service/perfil/usuario.service';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { BlogService } from './shared/service/blog/blog.service';
import { OfertasService } from './shared/service/ofertas/ofertas.service';
import { CategoriaService } from './shared/service/categoria/categoria.service';
import { TipsService } from './shared/service/tips/tips.service';
import { OfertaDetalheModule } from './page/core/oferta-detalhe/oferta-detalhe.module';
import { OfertasModule } from './page/core/ofertas/ofertas.module';
import { PlanosModule } from './page/planos/planos/planos.module';
import { PlanoDetalheModule } from './page/planos/plano-detalhe/plano-detalhe.module';
import { CentralDeAjudaModule } from './page/central-de-ajuda/central-de-ajuda/central-de-ajuda.module';
import { FaqModule } from './page/central-de-ajuda/faq/faq.module';
import { GuiaDoUsuarioModule } from './page/central-de-ajuda/guia-do-usuario/guia-do-usuario.module';
import { RegulamentoDetalheModule } from './page/central-de-ajuda/regulamento-detalhe/regulamento-detalhe.module';
import { RegulamentosModule } from './page/central-de-ajuda/regulamentos/regulamentos.module';
import { EditarPerfilModule } from './page/perfil/editar-perfil/editar-perfil.module';
import { MeuPerfilModule } from './page/perfil/meu-perfil/meu-perfil.module';
import { EstornosModule } from './page/financeiro/estornos/estornos.module';
import { ExpirarModule } from './page/financeiro/expirar/expirar.module';
import { MeusEmprestimosModule } from './page/financeiro/meus-emprestimos/meus-emprestimos.module';
import { SaldoModule } from './page/financeiro/saldo/saldo.module';
import { SolicitarEmprestimoModule } from './page/financeiro/solicitar-emprestimo/solicitar-emprestimo.module';
import { FazerTransferenciaModule } from './page/transferencias/fazer-transferencia/fazer-transferencia.module';
import { FazerTransferenciaDetalheModule } from './page/transferencias/fazer-transferencia-detalhe/fazer-transferencia-detalhe.module';
import { MinhasTransferenciasModule } from './page/transferencias/minhas-transferencias/minhas-transferencias.module';
import { SolicitarTransferenciaModule } from './page/transferencias/solicitar-transferencia/solicitar-transferencia.module';
import { SolicitarTransferenciaDetalheModule } from './page/transferencias/solicitar-transferencia-detalhe/solicitar-transferencia-detalhe.module';
import { PanielVendasModule } from './page/vendas/painel-vendas/painel-vendas.module';
import { ValidarVoucherModule } from './page/vendas/validar-voucher/validar-voucher.module';
import { PainelComprasModule } from './page/compras/painel-compras/painel-compras.module';
import { PainelOfertasModule } from './page/minhas-ofertas/painel-ofertas/painel-ofertas.module';
import { PainelOfertasCadastroModule } from './page/minhas-ofertas/painel-ofertas-cadastro/painel-ofertas-cadastro.module';
import { PainelIndicacaoModule } from './page/indicacoes/painel-indicacao/painel-indicacao.module';
import { PainelMinhasIndicacoesModule } from './page/indicacoes/painel-minhas-indicacoes/painel-minhas-indicacoes.module';
import { EADModule } from './page/ead/ead/ead.module';
import { EADDetalheModule } from './page/ead/ead-detalhe/ead-detalhe.module';
import { RodadaDeNegociosModule } from './page/rodada-de-negocios/rodada-de-negocios/rodada-de-negocios.module';
import { EstatisticasService } from './shared/service/perfil/estatisticas.service';
import { TransacaoService } from './shared/service/financeiro/transacao.service';
import { ExpirarService } from './shared/service/financeiro/expirar.service';
import { EmprestimoService } from './shared/service/financeiro/emprestimo.service';
import { ConfirmarCadastroModule } from './page/onboarding/confirmar-cadastro/confirmar-cadastro.module';
import { TransferenciaService } from './shared/service/transferencias/transferencia.service';
import { EstornoService } from './shared/service/financeiro/estorno.service';
import { VendasService } from './shared/service/vendas/vendas.service';
import { ComprasService } from './shared/service/compras/compras.service';
import { ComissaoService } from './shared/service/comissao/comissao.service';
import { IndicacaoService } from './shared/service/indicacao/indicacao.service';
import { RodadaDeNegociosService } from './shared/service/rodada-de-negocios/rodada-de-negocios.service';
import { EADService } from './shared/service/ead/ead.service';
import { CartaoService } from './shared/service/financeiro/cartao.service';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage  } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ParceirosService } from './shared/service/parceiros/parceiros.service';
import { ParceirosModule } from './page/core/parceiros/parceiros.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { SolicitarAntecipacaoModule } from './page/financeiro/solicitar-antecipacao/solicitar-antecipacao.module';
import { SafePipe } from './arquitetura/pipe/SafePipe';
import { ExcluirContaModule } from './page/excluir-conta/excluir-conta.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { MeusEmprestimosEmRSModule } from './page/financeiro/meus-emprestimos-em-rs/meus-emprestimos-em-rs.module';
import { LandingPageModule } from './page/landingpage/landingpage.module';
import { LandingpageService } from './shared/service/landingpage/landingpage.service';
import { CarouselModule } from 'primeng/carousel';
import { FinalizarCompraModule } from './page/core/finalizar-compra/finalizar-compra.module';
import { DashboardModule } from './page/perfil/dashboard/dashboard.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { SliderModule } from 'primeng/slider';
import { EditarEnderecoModule } from './page/perfil/editar-endereco/editar-perfil.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    PdfViewerModule,
    CarouselModule,
    ToastModule,
    DropdownModule,
    ChipModule,
    SliderModule,
    ArquiteturaModule,
    HomeModule, 
    LoginModule,
    CadastrarModule,
    ConfirmarCadastroModule,
    RecuperarModule,
    OfertaDetalheModule,
    OfertasModule,
    PlanosModule,
    PlanoDetalheModule,
    CentralDeAjudaModule,
    FaqModule,
    GuiaDoUsuarioModule,
    RegulamentoDetalheModule,
    RegulamentosModule,
    EditarPerfilModule,
    MeuPerfilModule,
    DashboardModule,
    EditarEnderecoModule,
    EstornosModule,
    ExpirarModule,
    MeusEmprestimosModule,
    MeusEmprestimosEmRSModule,
    SaldoModule,
    SolicitarEmprestimoModule,
    FazerTransferenciaModule,
    FazerTransferenciaDetalheModule,
    MinhasTransferenciasModule,
    SolicitarTransferenciaModule,
    SolicitarTransferenciaDetalheModule,
    PanielVendasModule,
    ValidarVoucherModule,
    PainelComprasModule,
    PainelOfertasModule,
    PainelOfertasCadastroModule,
    PainelIndicacaoModule,
    PainelMinhasIndicacoesModule,
    EADModule,
    EADDetalheModule,
    RodadaDeNegociosModule,
    ParceirosModule,
    SolicitarAntecipacaoModule,
    FinalizarCompraModule,
    ExcluirContaModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,

    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage())
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    DatePipe,
    Clipboard,
    SocialSharing,
    MessageService,
    LoginService,
    EnderecoService,
    UsuarioService,
    EstatisticasService,
    BlogService,
    OfertasService,
    CategoriaService,
    TipsService,
    TransacaoService,
    ExpirarService,
    EmprestimoService,
    TransferenciaService,
    EstornoService,
    VendasService,
    ComprasService,
    CurrencyPipe,
    DecimalPipe,
    ComissaoService,
    IndicacaoService,
    RodadaDeNegociosService,
    EADService,
    CartaoService,
    Camera,
    IonContent,
    Geolocation,
    ParceirosService,
    LandingpageService,
    LandingPageModule,
    InAppBrowser,
    HTTP,
    File,
    SafePipe,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
  constructor() {}
}
