import { Component } from "@angular/core";
import { LoadingController, NavController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { Router } from "@angular/router";
import { RodadaDeNegociosService } from "src/app/shared/service/rodada-de-negocios/rodada-de-negocios.service";
import { environment } from "src/environments/environment";
import { getAccountId, getToken, getUsuarioLogado } from "../../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'cmp-rodada-de-negocios',
  templateUrl: './rodada-de-negocios.component.html',
  styleUrls: [
    './rodada-de-negocios.component.scss',
  ]
})
export class RodadaDeNegociosComponent extends BaseComponent {
  theme = environment.theme_name;
  url!: string;

  showPitchModal = false;

  businessRound: any;
  currentBusinessRoundCompany: any;


  noBusinessRound = false;

  companiesThatLiked: any[] = [];
  companiiesThatIamInterested: any[] = [];

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected userService: UsuarioService,
    private service: RodadaDeNegociosService,
    protected navCtrl: NavController,
    public messageService?: MessageService
  ) {
    super(router, toastController, loadingController);
  }

  ngOnInit(): void {
    this.fetchBusinessRound();
  }

  onEnviar() {
    const urlPattern = /^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;

    if (!urlPattern.test(this.url)) {
      this.message("Link inválido!");
      return;
    }

    this.service.avaliacao(this.url).then((resultado) => {
      if (resultado.videoPitch) {
        if (resultado.videoPitch.videoPitchStatus) {
          if (resultado.videoPitch.videoPitchStatus.name == 'PENDING')
            this.message('Seu vídeo está em análise!');
        }
      }
    }).catch((ex) => {
      this.message(ex.error.msg);
    });
  }

  async fetchBusinessRound() {
    var token = getToken();
    var accountId = getAccountId();

    this.openLoading('Carregando...');

    const user = await this.userService.get(token, accountId)

    if (user.businessRounds.length > 0) {
      this.businessRound = user.businessRounds[0];

      this.service.startBusinessRound(this.businessRound.id).then((resultado) => {
        this.currentBusinessRoundCompany = resultado.next,
        this.companiesThatLiked = resultado.likes;
        this.companiiesThatIamInterested = resultado.liked;
      }).catch((ex) => {
        this.noBusinessRound = true;
        this.message(ex.error.msg);
      }).finally(() => {
        this.closeLoading();
      });
    }
  }

  viewPitchVideo() {
    window.open(this.currentBusinessRoundCompany.pitch, '_blank');
  }

  markAsInterested() {
    this.service.markAsInterested(this.businessRound.id, this.currentBusinessRoundCompany.id).then((resultado) => {
      this.fetchBusinessRound();
    }).catch((ex) => {
      this.message(ex.error.msg);
    });
  }

  maskAsNotInterested() {
    this.service.maskAsNotInterested(this.businessRound.id, this.currentBusinessRoundCompany.id).then((resultado) => {
      this.fetchBusinessRound();
    }).catch((ex) => {
      this.message(ex.error.msg);
    });
  }

  openWhatsApp(company: any) {
    window.open(`https://api.whatsapp.com/send?phone=${company.whatsapp}&text=Olá!`, '_blank');
  }

  viewOffers() {
    this.navCtrl.navigateForward('/core/ofertas', { queryParams: { texto: this.currentBusinessRoundCompany.name } });
  }
}


