import { Component } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";
import { BaseComponent } from "../../base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { OfertasService } from "src/app/shared/service/ofertas/ofertas.service";
import { OfertaResumida } from "src/app/shared/model/ofertas/oferta-resumida.model";
import { Ordenacao } from "src/app/shared/model/geral/order.model";
import { getCityName, getState, getStateName, setCityName, setStateName } from "../../service/base.service";
import { environment } from "src/environments/environment";
import { EstadoModel } from "src/app/shared/model/endereco/estado.model";
import { CidadeModel } from "src/app/shared/model/endereco/cidade.model";
import { EnderecoService } from "src/app/shared/service/endereco/endereco.service";
import { Categoria } from "src/app/shared/model/categoria/categoria.model";
import { CategoriaService } from "src/app/shared/service/categoria/categoria.service";

@Component({
  selector: 'cmp-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: [
    './ofertas.component.scss',
  ]
})
export class OfertasComponent extends BaseComponent {
  theme = environment.theme_name;
  location = {
    city: getCityName(),
    state: getStateName()
  };

  limit: number = 12;
  page: number = 0;
  ofertas: OfertaResumida[] = [];
  ordenacao: Ordenacao;
  showFiltro: boolean;
  estadoProduto: number;
  minPrice: string;
  maxPrice: string;

  rangePreco: number[] = [20, 80];

  count: number;
  offset: number;
  max: number;
  range: any;

  texto: string = '';

  isOrcamento: boolean;
  isProduto: boolean;
  isServico: boolean;
  isLoja: boolean;

  estados: EstadoModel[] = [];
  cidades: CidadeModel[] = [];

  estadoAtual: any;
  cidadeAtual: any;

  maxCategorias: number = 7;

  listaOrdenacao = [
    { name: 'lastUpdated', value: 'desc', description: 'Atualizadas recentemente' },
    { name: 'relevancy', value: 'desc', description: 'Mais relevante' },
    { name: 'price', value: 'desc', description: 'Maior preço' },
    { name: 'price', value: 'asc', description: 'Menor preço' },
    { name: 'bestSellers', value: 'desc', description: 'Mais vendidas' },
    { name: 'stars', value: 'desc', description: 'Melhor avaliadas' }
  ];

  listaCondicoes = [
    { name: 'Produto novo', value: '1', description: 'Produto novo' },
    { name: 'Produto usado', value: '2', description: 'Produto usado' }
  ];

  categorias: (Categoria & { selected?: boolean })[] = [];

  constructor(
    public router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected service: OfertasService,
    private enderecoService: EnderecoService,
    protected serviceCategoria: CategoriaService,
    private route: ActivatedRoute) {
    super(router, toastController, loadingController);

    this.route.queryParams.subscribe(queryParams => {
      this.texto = queryParams['texto'];
    });
  }

  ngOnInit(): void {
    this.offset = 0;
    this.max = 12;

    this.criaOrdenacao();
    this.listar(this.texto);
    this.listarEstados();
    this.listarCategorias();
  }

  criaOrdenacao() {
    this.ordenacao = new Ordenacao();
    this.ordenacao.name = 'lastUpdated';
    this.ordenacao.value = 'desc';
  }

  listar(texto: string) {
    const state = getState();
    this.texto = texto;

    this.openLoading('Carregando...');
    this.service.listar(texto, this.offset, this.limit, state, this.ordenacao).then((resultado) => {
      if (resultado) {
        if (resultado.msg) {
          this.message(resultado.msg);
        } else {
          this.count = resultado.listFullSize;
          this.ofertas = resultado.offers as OfertaResumida[];
        }
      }
    }).catch((error) => {
      this.message(JSON.parse(error.error).msg);
    }).finally(() => {
      this.closeLoading();
      window.scrollTo(0, 0);
    });
  }

  listarEstados() {
    this.enderecoService.estados().then((resultado) => {
      this.estados = resultado.states as EstadoModel[];
    });
  }

  listarCidades() {
    this.enderecoService.cidades(this.estadoAtual).then((resultado) => {
      this.cidades = resultado.cities as CidadeModel[];
    });
  }

  async loadData(event) {
    this.offset += this.max;

    var state = getState();
    this.texto = this.texto;

    const categoriasSelecionadas = this.categorias.filter(c => c.selected).map(c => c.id);

    this.service.listar(
      this.texto,
      this.offset,
      this.limit,
      state,
      this.ordenacao,
      this.estadoProduto,
      categoriasSelecionadas,
      this.minPrice,
      this.maxPrice
    ).then((resultado) => {
      if (event.target) {
        event.target.complete();
      }

      if (resultado) {
        if (!resultado.msg) {
          var ofertas = resultado.offers as OfertaResumida[];
          this.ofertas = this.ofertas.concat(ofertas);
        }
      }
    }).catch((error) => {
      this.message(JSON.parse(error.error).msg);
    });
  }

  onFechar() {
    this.showFiltro = false;
  }

  onFiltrar() {
    this.listar(this.texto);
    this.showFiltro = false;
  }

  onSelectCategorias(categorias: any) {
    this.categorias = categorias;
  }

  listarCategorias(): void {
    this.serviceCategoria.listar().then((resultado) => {
      this.categorias = resultado.categories as Categoria[];
    });
  }

  onSelectOrdenarPor($event: any) {
    if ($event) {
      this.ordenacao.name = $event.value.name;
      this.ordenacao.value = $event.value.value;
    }
  }

  aplicarFiltro() {
    this.showFiltro = false;
    this.listar(this.texto);
  }

  onEstadoSelected($event: any) {
    this.estadoAtual = $event.value.id;

    this.location.state = $event.value.name;
    setStateName($event.value.name);
    this.listarCidades();
  }

  onCidadeSelected($event: any) {
    this.location.city = $event.value.name;
    setCityName($event.value.name);
    this.cidadeAtual = $event;
  }

  onClickCategoria(categoria: Categoria & { selected?: boolean }) {
    this.categorias = this.categorias.map(c => c.id === categoria.id ? { ...c, selected: !c.selected } : c);
  }

  toggleVerMaisCategorias() {
    if (this.maxCategorias !== this.categorias.length) {
      this.maxCategorias = this.categorias.length;
    } else {
      this.maxCategorias = 7;
    }
  }

  onSelectCondicaoProduto($event: any) {
    this.estadoProduto = $event.value.value;
  }

  limparFiltros() {
    window.location.reload();
  }
}
