import { Component, Input } from "@angular/core";
import { LoadingController, MenuController, NavController, Platform, ToastController } from "@ionic/angular";
import { getAccountId, getMobile, getToken, getUsuarioLogado } from "../service/base.service";
import { UsuarioService } from "src/app/shared/service/perfil/usuario.service";
import { EnderecoService } from "src/app/shared/service/endereco/endereco.service";
import { BaseComponent } from "../base.component";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cmp-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss']
})
export class RodapeComponent extends BaseComponent {
  logo = `${environment.theme_assets}image/logo-Footer.svg`;
  storesBadges = `${environment.theme_assets}image/storesBadges.svg`;
  facebook = `${environment.theme_assets}image/socialMedia/facebook.svg`;
  YouTube = `${environment.theme_assets}image/socialMedia/youtube.svg`;
  instagram = `${environment.theme_assets}image/socialMedia/instagram.svg`;
  twitter = `${environment.theme_assets}image/socialMedia/item (1).svg`;
  phone = `${environment.theme_assets}image/socialMedia/phone.svg`;
  whats = `${environment.theme_assets}image/socialMedia/whats.svg`;
  email = `${environment.theme_assets}image/socialMedia/email.svg`;
  chevronUp = `${environment.theme_assets}image/socialMedia/chevron-up.svg`;

  logado: boolean = false;

  @Input() tabSelected!: string;

  constructor(
    public router: Router,
    private platform: Platform,
    public toastController: ToastController,
    public loadingController: LoadingController,
    protected menu: MenuController,
    protected navCtrl: NavController,
    protected service: UsuarioService,
    protected serviceEstado: EnderecoService) {
    super(router, toastController, loadingController);
  }

  override ngOnInit(): void {
    this.platform.ready().then(() => {
      this.verificarLogin();
    });
  }

  verificarLogin() {
    var usuario = JSON.parse(getUsuarioLogado());
    if (usuario)
      this.logado = true;
  }

  onHome() {
    this.navCtrl.navigateForward('/core/home');
  }

  onOfertas() {
    this.navCtrl.navigateForward('/core/ofertas');
  }

  onFinancas() {
    this.navCtrl.navigateForward('/financeiro/saldo');
  }

  onAbout() {
    this.navCtrl.navigateForward('/core/home');
  }

  navegarParaCentralDeAjuda() {
    this.navCtrl.navigateForward('/central-de-ajuda/central-de-ajuda');
  }

  scrollToHeader(): void {
    document.body.scrollTop = 0;
  }
}
